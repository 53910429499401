export default [
  {
    header: '股票',
  },
  {
    title: '主页',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: '热门话题',
    route: 'hot',
    icon: 'CoffeeIcon',
  },
  {
    title: '行情中心',
    icon:'TrendingUpIcon',
    route: null,
  },
  {
    title: '筛选器',
    icon:'SearchIcon',
    route: 'search',
  },
  {
    title: '新股日历',
    icon:'CalendarIcon',
    route: 'calendar',
  },
  {
    header: '用户',
  },
  {
    title: '自选',
    route: 'selected',
    icon: 'CheckCircleIcon',
  },
  // {
  //   title: '互动',
  //   route: null,
  //   icon: 'MessageCircleIcon',
  // },
  // {
  //   title: '模拟交易',
  //   route: null,
  //   icon: 'ActivityIcon',
  // },
  {
    header: '账户',
  },
  // {
  //   title: '账户中心',
  //   route: null,
  //   icon: 'UserIcon',
  // },
  {
    title: '设置中心',
    route: "settings",
    icon: 'SettingsIcon',
  },
  {
    header: '系统',
  },
  {
    title: 'FAQ',
    route: null,
    icon: 'SmileIcon',
  },
  {
    title: '反馈',
    route: null,
    icon: 'MailIcon',
  },
  {
    title: '相关协议',
    route: null,
    icon: 'CopyIcon',
  },
  {
    title: '开放源代码',
    route: null,
    icon: 'CodeIcon',
  },
]
