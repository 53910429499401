<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive">
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <ul class="nav">
      <li>
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
          <dark-Toggler class=" d-lg-block"/>
        </div>
      </li>
    </ul>


    <b-navbar-nav class="nav align-items-center ml-auto">

      <!--搜索框-->
      <li class="nav-item nav-search">
        <!-- Icon -->
        <a
            href="javascript:void(0)"
            class="nav-link nav-link-search"
            @click="showSearchBar = true">
          <feather-icon
              icon="SearchIcon"
              size="21"/>
        </a>
        <!-- Input -->
        <div
            class="search-input"
            :class="{'open': showSearchBar}">
          <div class="search-input-icon">
            <feather-icon icon="SearchIcon"/>
          </div>
          <b-form-input
              v-if="showSearchBar"
              placeholder="请输入关键字"
              autofocus
              autocomplete="off"
              @keyup.enter="search"
              @blur.stop="showSearchBar = false;"/>
          <div
              class="search-input-close"
              @click="showSearchBar = false;">
            <feather-icon icon="XIcon"/>
          </div>

        </div>
      </li>

      <!--未登录状态框-->
      <div
          right
          v-show="!logged">
        <router-link
            to="register">
          <b-button
              v-b-tooltip.hover.v-primary
              title="注册"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              style="margin-left: 10px">
            <feather-icon icon="UserPlusIcon"/>
          </b-button>
        </router-link>
        <router-link
            to="login">
          <b-button
              v-b-tooltip.hover.v-primary
              title="登录"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              style="margin-left: 10px">
            <feather-icon icon="LogInIcon"/>
          </b-button>
        </router-link>
      </div>


      <!--已登录状态框-->
      <b-nav-item-dropdown
          right
          v-show="logged"
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user_name }}
            </p>
            <span class="user-status">{{ user_type }}</span>
          </div>
          <b-avatar
              size="40"
              v-model="user_avatar"
              variant="light-primary"
              :src="user_avatar"
              class="badge-minimal"/>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
          />
          <span>个人主页</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="MailIcon"
              class="mr-50"
          />
          <span>信箱</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="ActivityIcon"
              class="mr-50"/>
          <span>模拟交易</span>
        </b-dropdown-item>
        <b-dropdown-divider/>

        <b-dropdown-item
            link-class="d-flex align-items-center"
            :to="{name:'settings'}">
          <feather-icon
              size="16"
              icon="SettingsIcon"
              class="mr-50"/>
          <span>账号设置</span>
        </b-dropdown-item>


        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"/>
          <span>退出</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BFormInput, BButton, VBTooltip,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {ref, watch} from '@vue/composition-api'
import Toast from "@core/components/toastification/ToastificationContent";
import User from "@/store/userInfo";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormInput,
    DarkToggler,
    BButton,
    VBTooltip,
  },
  async created() {
    await User.checkUserInfo()
    let userInfo = User.userInfo
    this.logged = (userInfo !== null)
    if (!this.logged){
      return
    }
    if (userInfo.user_profile.user_nickname === null || userInfo.user_profile.user_nickname === "") {
      this.user_name = userInfo.user.user_name
    } else {
      this.user_name = userInfo.user_profile.user_nickname
    }
    if (userInfo.user.user_type === "01") {
      this.user_type = "管理员"
    } else if (userInfo.user.user_type === "02") {
      this.user_type = "注册用户"
    } else if (userInfo.user.user_type === "03") {
      this.user_type = "订阅用户"
    }
    this.user_avatar = userInfo.user_profile.user_avatar
  },
  fresh(){
    console.log("123")
    console.log(User.userInfo.user_profile.user_avatar)
    this.user_avatar = User.userInfo.user_profile.user_avatar
  },
  data() {
    return {
      logged: false,
      user_name: "",
      user_type: "",
      user_avatar: "",
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  setup() {
    const showSearchBar = ref(false)
    return {
      showSearchBar,
    }
  },
  methods: {
    search() {

    },
    logout() {
      User.userInfo = null
      this.$http.get('/api/system/user/logout')
      this.logged = false
      this.$router.push({name: 'home'})
      this.showToast("success", "成功", "AlertCircleIcon", "您已退出", "top-center", 2000)
    },
    showToast(variant, title, icon, text, position, timeout) {
      this.$toast({
            component: Toast,
            props: {
              title: title,
              icon: icon,
              text: text,
              variant,
            },
          },
          {
            position,
            timeout
          })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
