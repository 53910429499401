<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
          class="ml-25"
          href="https://www.utopiaxc.cn/"
          target="_blank"
      >UtopiaXC </b-link>
      <span class="d-none d-sm-inline-block"> All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Open sourced on
      <b-link
          class="ml-25"
          href="https://git.utopiaxc.cn/utopiaxc-graduation-project"
          target="_blank">UtopiaXC's GitLab
        <feather-icon
            icon="GitlabIcon"
            size="16"
            class="text-danger stroke-current"/>
      </b-link>
    </span>

  </p>
</template>

<script>
import {BLink} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
